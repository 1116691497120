/* General styling for the User Page */
.user-page-container {
  font-family: 'Arial', sans-serif;
  background-color: #f4f6f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

/* Center the user container within the page */
.user-container {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
}

.header-title {
  font-size: 2rem; /* Large font size for the title */
  font-weight: 700; /* Bold font for emphasis */
  color: #bf4000; /* Dark color for the title */
  text-align: center; /* Center the title text */
  margin-top: 40px; /* Space above the title */
  margin-bottom: 20px; /* Space below the title */
  font-family: 'Helvetica', sans-serif; /* Clean and modern font */
}

.mobile-number-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  margin-bottom: 10px;
}

.input-field:focus {
  border-color: #4a90e2;
}

.action-button {
  width: 100%;
  max-width: 300px;
  padding: 10px;
  font-size: 1rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.action-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.action-button:hover:not(:disabled) {
  background-color: #357ab7;
}

.error-message {
  color: red;
  text-align: center;
  font-size: 1rem;
  margin-top: 10px;
}

/* PDF Folder Layout */
.pdf-list-container {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.pdf-folder {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.pdf-folder-item {
  width: 100%;
  max-width: 250px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.3s ease;
  text-align: center;
}

.pdf-folder-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.folder-icon {
  width: 70px;
  height: 70px;
  background-color: #f5c300;
  position: relative;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.folder-icon::before {
  content: "";
  position: absolute;
  top: -15px;
  left: 10px;
  width: 50px;
  height: 20px;
  background-color: #f78f01;
  border-radius: 5px 5px 0 0;
}

.pdf-name {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
}

.view-pdf-button {
  padding: 8px 16px;
  background-color: #4a90e2;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 0.9rem;
}

.view-pdf-button:hover {
  background-color: #357ab7;
}

.password-info {
  margin-top: 15px;
  padding: 10px;
  background-color: #e8f5e9;
  border: 1px solid #81c784;
  border-radius: 8px;
  font-size: 16px;
  color: #333;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .header-title {
    font-size: 1.5rem;
  }

  .pdf-folder-item {
    width: 100%;
    max-width: 100%; /* Full width for smaller screens */
  }

  .input-field, .action-button {
    max-width: 100%;
  }
}
.header-message {
  font-size: 1rem; /* Set a readable font size */
  font-weight: 500; /* Semi-bold text for emphasis */
  color: #555; /* Soft dark gray color for the text */
  text-align: center; /* Center the text */
  margin-top: 20px; /* Add spacing above the text */
  margin-bottom: 20px; /* Add spacing below the text */
  font-family: 'Helvetica', sans-serif; /* Use a clean, modern font */
  line-height: 1.6; /* Line height for better readability */
  padding: 10px 20px; /* Add padding to create space inside */
  background-color: #f0f8ff; /* Light background color */
  border-radius: 8px; /* Rounded corners for a soft look */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  max-width: 90%; /* Make it responsive */
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
}

