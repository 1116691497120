/* Flower-themed styling */

.rose-container {
    background-color: #f7f1f5;
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .orchid-header {
    font-family: 'Arial', sans-serif;
    text-align: center;
    color: #6a1b9a;
  }
  
  .daisy-form-group,
  .tulip-form-group {
    margin-bottom: 20px;
  }
  
  .lily-label {
    display: block;
    font-size: 16px;
    color: #388e3c;
    margin-bottom: 8px;
  }
  
  .sunflower-select,
  .lily-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
  }
  
  .sunflower-select {
    background-color: #fff9c4;
  }
  
  .lily-input {
    background-color: #fff;
  }
  
  .button-rose {
    background-color: #f48fb1;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .button-rose:hover {
    background-color: #f06292;
  }
  
  .progress-bar-container {
    background-color: #f1f1f1;
    border-radius: 5px;
    height: 20px;
    margin-bottom: 10px;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #f06292;
    border-radius: 5px;
    text-align: center;
    color: white;
    line-height: 20px;
    font-weight: bold;
  }
  
  .progress-text {
    font-size: 14px;
  }
  
  .daisy-label {
    font-size: 16px;
    color: #388e3c;
    margin-bottom: 8px;
  }
  