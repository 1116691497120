/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

body {
  background-color: #f4f7fb;
  color: #333;
}

/* Admin Page Container */
.admin-page {
  width: 90%;
  max-width: 1200px;
  margin: 30px auto;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header */
h1 {
  text-align: center;
  color: #4a90e2;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

/* Upload Section */
.upload-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.upload-section input,
.upload-section select,
.upload-section button {
  padding: 12px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.upload-section input[type="file"] {
  padding: 5px;
}

.upload-section button {
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s;
}

.upload-section button:hover {
  background-color: #3578e5;
}

.progress-bar {
  margin-top: 10px;
  background-color: #f2f2f2;
  width: 100%;
  height: 10px;
  border-radius: 5px;
}

.progress {
  background-color: #4a90e2;
  height: 100%;
  border-radius: 5px;
}

/* PDF List Section */
.pdf-list {
  margin-top: 30px;
}

.pdf-list table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.pdf-list th,
.pdf-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #f2f2f2;
}

.pdf-list th {
  background-color: #f4f7fb;
  color: #4a90e2;
}

.pdf-list td {
  color: #333;
}

.pdf-list tr:hover {
  background-color: #f9f9f9;
}

.pdf-list td .icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #4a90e2;
  transition: color 0.3s;
}

.pdf-list td .icon:hover {
  color: #3578e5;
}

.pdf-list td .icon.edit-icon {
  margin-right: 10px;
}

.pdf-list td .icon.delete-icon {
  color: #e94f4f;
}

.pdf-list td .icon.delete-icon:hover {
  color: #d14e4e;
}

.pdf-list td .icon.view-icon {
  color: #4caf50;
}

.pdf-list td .icon.view-icon:hover {
  color: #45a049;
}

/* Edit Mode */
/* Edit Mode Button Container */
.edit-mode {
  display: flex;
  gap: 15px; /* Adds space between the buttons */
  justify-content: flex-start; /* Align buttons to the left (can be changed) */
}

/* Button styles */
button {
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1rem;
}

/* Individual button styles */
.view-btn {
  background-color: #2196F3;
  color: white;
}

.edit-btn {
  background-color: #FF9800;
  color: white;
}

.delete-btn {
  background-color: #F44336;
  color: white;
}

.save-btn {
  background-color: #4CAF50;
  color: white;
}

.cancel-btn {
  background-color: #9E9E9E;
  color: white;
}

/* Button hover effect */
button:hover {
  opacity: 0.8;
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .upload-section {
    gap: 10px;
  }

  .upload-section input,
  .upload-section select,
  .upload-section button {
    padding: 8px;
  }

  .pdf-list table {
    font-size: 0.9rem;
  }

  .pdf-list th,
  .pdf-list td {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .admin-page {
    width: 95%;
    padding: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  .upload-section {
    padding: 15px;
  }
}
