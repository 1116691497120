/* src/Components/TelegramPopup.css */

.telegram-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top */
  }
  
  .telegram-popup-content {
    background: white;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 90%; /* Adjust width for smaller screens */
    max-width: 400px; /* Set maximum width */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative; /* Positioning for the close button */
  }
  
  .telegram-image {
    width: 80px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    margin: 10px 0; /* Spacing around the image */
  }
  
  .telegram-popup h2 {
    margin: 0 0 10px;
    font-size: 1.5rem; /* Responsive font size */
  }
  
  .telegram-popup p {
    margin: 0 0 20px;
    font-size: 1rem; /* Responsive font size */
  }
  
  .join-button {
    background-color: #007bff; /* Primary color */
    color: white;
    border: none;
    border-radius: 5px; /* Rounded corners */
    padding: 10px 15px; /* Padding for button */
    cursor: pointer;
    margin-top: 10px; /* Margin above the button */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
    font-size: 1rem; /* Responsive font size */
    width: 100%; /* Full width on small screens */
    max-width: 150px; /* Limit width for larger screens */
  }
  
  .join-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
    
  }
  
  .close-x {
    position: absolute; /* Position the close button */
    top: 10px;
    right: 10px;
    background: none; /* No background */
    border: none; /* No border */
    font-size: 28px; /* Increase font size */
    color: #565353; /* Gray color */
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .close-x:hover {
    color: #dc3545; /* Change color on hover */
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 600px) {
    .telegram-popup-content {
      padding: 15px; /* Adjust padding for smaller screens */
    }
  
    .telegram-popup h2 {
      font-size: 1.20rem; /* Smaller heading on mobile */
    }
  
    .telegram-popup p {
      font-size: 0.9rem; /* Smaller text on mobile */
    }
  
    .join-button {
      font-size: 0.9rem; /* Smaller button text */
      padding: 12px 12px; /* Smaller button padding */
    }
  
    .close-x {
      font-size: 30px; /* Smaller close button */
    }
  }
  