/* Navbar.css */

.admin-navbar {
    display: flex; /* Flexbox for alignment */
    justify-content: space-between; /* Space between logo and links */
    align-items: center; /* Center items vertically */
    background-color: #34495e; /* Darker blue background */
    padding: 15px 30px; /* Padding for spacing */
    position: relative; /* For dropdown positioning */
    color: white; /* White text color */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.navbar-logo h2 {
    margin: 0; /* Remove default margin */
    font-size: 24px; /* Logo font size */
    font-weight: bold; /* Bold logo text */
}

.navbar-toggle {
    display: none; /* Hidden on large screens */
    cursor: pointer; /* Pointer cursor */
    font-size: 24px; /* Icon size */
}

.navbar-links {
    list-style-type: none; /* Remove bullets from list */
    padding: 0; /* Remove padding */
    display: flex; /* Flexbox for links */
    align-items: center; /* Center items vertically */
    transition: max-height 0.3s ease; /* Transition for menu */
}

.navbar-links li {
    margin: 0 15px; /* Spacing between links */
}

.navbar-links a {
    color: white; /* White link color */
    text-decoration: none; /* Remove underline */
    font-size: 16px; /* Font size for links */
    position: relative; /* Position for underline effect */
    transition: color 0.3s; /* Smooth transition */
}

.navbar-links a:hover {
    color: #e67e22; /* Change color on hover */
}

.navbar-links a::after {
    content: ''; /* Empty content for pseudo-element */
    display: block; /* Block display */
    height: 2px; /* Height of the underline */
    width: 0; /* Initial width of underline */
    background: #e67e22; /* Color of underline */
    transition: width 0.3s; /* Smooth transition for underline */
    position: absolute; /* Absolute position for underline */
    left: 50%; /* Center horizontally */
    bottom: -5px; /* Position below text */
}

.navbar-links a:hover::after {
    width: 100%; /* Full width on hover */
    left: 0; /* Align to the left */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .navbar-toggle {
        display: block; /* Show toggle button on small screens */
    }

    .navbar-links {
        position: absolute; /* Position links absolutely */
        top: 40px; /* Position below navbar */
        left: 0; /* Align to left */
        right: 0; /* Align to right */
        background-color: #34495e; /* Same background */
        max-height: 0; /* Initially hidden */
        overflow: hidden; /* Hide overflow */
        flex-direction: column; /* Stack links vertically */
        transition: max-height 0.3s ease; /* Transition for opening */
        z-index: 10; /* Ensure it appears above other content */
    }

    .navbar-links.active {
        max-height: 300px; /* Limit maximum height when open */
    }

    .navbar-links li {
        margin: 10px 0; /* Spacing between stacked links */
        text-align: center; /* Center text in mobile view */
    }
}
