/* src/Components/Home.css */
.logo-container {
  
  align-items: center;
  justify-content: center;
  padding: 8px;
  background: linear-gradient(
    135deg, /* Angle of the gradient */
    rgba(115, 12, 29, 0.892) 0%, /* Start color */
    rgba(204, 50, 66, 0.892) 100% /* End color */
  ); /* Adjust these colors as needed */
  margin-bottom: 20px; /* Space below the logo */
}

.logo {
  max-width: 100px; /* Limit logo size */
  margin-right: 15px; /* Space between logo and title */
}

.site-title {
  font-size: 2rem; /* Title size */
  color: #e7e7e7; /* Dark color for text */
  font-weight: bold; /* Make title bold */
  text-align: center; /* Center the title */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; /* Font family */
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .logo-container {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    padding: 15px; /* Reduced padding for mobile */
  }

  .logo {
    margin-bottom: 10px; /* Space below logo */
    max-width: 80px; /* Adjust logo size */
  }

  .site-title {
    font-size: 1.5rem; /* Smaller title size for mobile */
    text-align: center; /* Center the title */
  }
}




.home {
  margin: 0 auto; /* Center the home container */
  padding: 20px; /* Add some padding */
  background-color: #ffffff; /* Light background for contrast */
  border-radius: 8px; /* Rounded corners for a softer look */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.home h1 {
  text-align: center; /* Center the title */
  color: #333; /* Darker text color for better readability */
  margin-bottom: 20px; /* Space below the title */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Updated font */
}

.logo {
  display: block;
  margin: 0 auto; /* Center the logo */
  width: 150px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}
.search-container {
  display: flex; /* Flex display for better alignment */
  justify-content: center; /* Center the input within the container */
  margin-bottom: 20px; /* Space below the search container */
  background-color: #bc0451; ;
  padding: 20px;
  border-radius: 4px;
}

/* Existing styles for the search bar */
.search-bar {
  width: 100%; /* Full width search bar */
  max-width: 400px; /* Optional: limit max width for better layout */
  padding: 10px; /* Padding for comfort */
  border: 1px solid #ccc; /* Light border */
  border-radius: 4px; /* Rounded corners */
  font-size: 1rem; /* Base font size */
}

.product-list {
  display: grid; /* Use grid layout for product list */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive columns */
  gap: 20px; /* Space between grid items */
}
.product {
  background-color: white; /* White background for product item */
  border: 1px solid #ddd; /* Light border */
  border-radius: 6px; /* Rounded corners */
  padding: 15px; /* Padding for comfort */
  text-align: center; /* Center content */
  cursor: pointer; /* Indicate clickable item */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Animation for hover effect */
  position: relative; /* Required for positioning hot deal label */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
}

.product:hover {
  transform: scale(1.03); /* Slight scale effect on hover */
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Increase shadow on hover for emphasis */
}

.product-image-container {
  width: 100%; /* Make the image container take full width */
  height: 300px; /* Fixed height for square box */
  overflow: hidden; /* Hide overflow to maintain square shape */
  position: relative; /* For positioning hot deal label */
}

.product img {
  width: 100%; /* Make the image responsive */
  height: 100%; /* Make the image fill the height of the container */
  object-fit: cover; /* Crop the image to fill the box */
  border-radius: 4px; /* Rounded corners */
}

.product h2 {
  font-size: 1.0rem; /* Base font size for product name */
  font-weight: 600;
  margin-top: 10px; /* Space above product name */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Updated font */
  color: #2b2b2b; /* Slightly lighter text color */
}

/* Hot Deal Label */
.hot-deal-label {
  background-color: #b71721; /* Bright green for visibility */
  color: white; /* White text for contrast */
  padding: 5px 10px; /* Padding for a comfortable size */
  border-radius: 4px; /* Rounded corners for a softer appearance */
  font-weight: bold; /* Make text bold for emphasis */
  position: absolute; /* Position it relative to the container */
  top: 10px; /* Position from the top */
  right: 10px; /* Position from the right */
  z-index: 10; /* Ensure it's above the product image */
  text-align: center; /* Center the text */
  font-size: 0.9rem; /* Set font size */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  transition: transform 0.2s ease; /* Smooth transition for hover effects */
}

.hot-deal-label:hover {
  transform: scale(1.1); /* Slightly increase size on hover */
}

/* Responsive Styles */
@media (max-width: 600px) {
  .search-bar {
    font-size: 0.9rem; /* Smaller font size for mobile */
  }

  .product-list {
    grid-template-columns: repeat(2, 1fr); /* Two columns on mobile */
  }

  .product {
    padding: 10px; /* Reduced padding on mobile */
  }

  .product-image-container {
    height: 200px; /* Smaller height on mobile */
  }

  .product img {
    height: 100%; /* Maintain responsive size */
  }

  .hot-deal-label {
    font-size: 0.6rem; /* Smaller font size for mobile */
    padding: 3px 8px; /* Adjust padding for smaller screens */
    top: 8px; /* Adjust top position */
    right: 8px; /* Adjust right position */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  .product h2 {
    font-size: 0.9rem; /* Smaller font size for product name */
  }
}

/* Loading Spinner */
.loading-spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto; /* Center the spinner */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.silder {
  width: 100%; /* Full width on mobile and web */
  max-width: 1200px; /* Limit maximum width on larger screens */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensure no inline spacing issues */
  margin: 0 auto; /* Center the image */
  border-radius: 4px; /* Optional: Add slight rounding to the corners */
  
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .silder {
    max-width: 100%; /* Take up the full width of the screen */
    border-radius: 4px; /* Optional: Smaller corner rounding on mobile */
  }
}
.title-container {
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
 
  
}

.seo-title {
  font-size: 1.5rem; /* Default font size */
  color: #bc0451; /* Set a color that matches your theme */
  text-align: center; /* Center the title text */
  font-weight: bold; /* Make the title bold */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; /* Font family */
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .title-container {
    padding: 5px; /* Less padding on mobile */
  }

  .seo-title {
    font-size: 1.25rem; /* Smaller font size for mobile */
    margin: 10px 0; /* Adjust margin for mobile */
  }
}
/* Pagination styles */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button, .pagination span {
  background-color: #007bff; /* Customize button color */
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 16px;
}

.pagination button.active {
  background-color: #0056b3; /* Darker blue for active page */
}

.pagination button:hover:not(.active) {
  background-color: #0056b3; /* Darker blue on hover */
}

.pagination span {
  padding: 10px 15px; /* Padding for ellipsis */
}
