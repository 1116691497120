/* Overall Page Styling */
.nri-brides-page {
    font-family: 'Arial', sans-serif;
    text-align: center;
    color: #333;
    padding: 20px;
  }
  
  .page-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    font-weight: bold;
    color: #2c3e50;
  }
  
  /* Full-Screen Modal */
  .full-screen-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    padding: 20px;
  }
  
  .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.close-button:hover {
  background-color: #f2f2f2; /* Light gray background on hover */
  transform: scale(1.1); /* Slight zoom on hover */
}

.close-button:active {
  transform: scale(0.95); /* Slightly shrink on click */
}

.close-button svg {
  color: #ff5c5c; /* Red color for the close icon */
}

@media (max-width: 768px) {
  .close-button {
    top: 15px;
    right: 15px;
    padding: 8px;
  }

  .close-button svg {
    size: 24px;
  }
}

  
  /* Image Viewer */
  .image-viewer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .full-image {
    max-width: 100%;
    max-height: 90vh;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  }
  
  /* Navigation Buttons */
  .nav-buttons {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
  }
  
  .nav-button {
    background-color: rgba(0, 0, 0, 0.6);
    border: none;
    padding: 10px;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .nav-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Image Grid */
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .image-item {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .image-item:hover {
    transform: scale(1.05);
  }
  
  .grid-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .grid-image:hover {
    transform: scale(1.05);
  }
  
  /* Responsive Design */
  @media (max-width: 600px) {
    .page-title {
      font-size: 2rem;
    }
  
    .image-grid {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
  
    .nav-button {
      font-size: 16px;
      padding: 8px;
    }
  
    
  }
  .full-image,
.grid-image {
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
  -user-drag: none;
}

/* Disable right-click menu */
body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}/* Main container */
.password-protection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    background-color: #f8f9fa;
    padding: 20px;
    text-align: center;
  }
  
  /* Logo styling */
  .password-protection .image {
    max-width: 300px; /* Larger logo size */
    width: 100%;
    margin-bottom: 30px; /* Increased space between the image and form */
  }
  
  .dov {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 20px;
    
    text-align: center;
  }
  
  /* Form styling */
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Input field styling */
  .password-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    transition: all 0.3s ease;
  }
  
  .password-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
  }
  
  /* Submit button styling */
  .submit-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Error message styling */
  .error-message {
    color: #e74c3c;
    font-size: 0.9rem;
    margin-top: 15px;
    text-align: center;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 480px) {
    .password-protection {
      padding: 15px;
    }
  
    .password-protection .image {
      max-width: 300px; /* Increase image size on mobile */
      margin-bottom: 40px; /* Increase space for better visual balance */
    }
  
    .dov {
      font-size: 1.2rem; /* Adjust text size for mobile */
    }
  
    .password-input {
      font-size: 1.1rem;
    }
  
    .submit-button {
      font-size: 1.1rem;
    }
  }
  