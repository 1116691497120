/* General Navbar Styling */
.navbar {
  background-color: #d9534f; /* Red theme for matrimony */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.brand-logo {
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
  font-weight: bold;
}

.hamburger {
  display: none; /* Hidden on desktop */
  flex-direction: column;
  justify-content: space-between;
  height: 18px;
  cursor: pointer;
  background: none;
  border: none;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  border-radius: 3px;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px;
  transition: transform 0.3s ease-in-out;
}

.nav-links.open {
  transform: translateX(0); /* For mobile view toggle */
}

.nav-link {
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
  background-color: #c9302c;
  color: #fff;
}

/* Dropdown Styling */
.nav-link-dropdown {
  position: relative;
}

.nav-link-dropdown > span {
  cursor: pointer;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-link-dropdown > span:hover {
  background-color: #c9302c;
}

.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
  min-width: 200px;
}

.nav-link-dropdown:hover > .submenu {
  display: block;
}

.submenu li {
  margin: 0;
  list-style: none;
}

.submenu li .nav-link {
  display: block;
  color: #333;
  padding: 10px 15px;
  text-decoration: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.submenu li .nav-link:hover {
  background-color: #f7f7f7;
  color: #d9534f;
  border-radius: 5px;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #d9534f;
    width: 100%;
    transform: translateX(100%);
    padding: 10px 0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links.open {
    transform: translateX(0);
  }

  .nav-link {
    width: 100%;
    text-align: left;
    padding: 10px 20px;
  }
}
