/* src/Components/AdminPanel.css */
/* Main Admin Panel Styling */
.admin-panel {
  padding: 20px;
  
  margin: 0 auto;
  background-color: #f9f9f9;
}

.admin-panel__title {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.admin-panel__error {
  color: red;
  text-align: center;
  margin-bottom: 15px;
}

/* Form Styling */
.admin-panel__form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

.admin-panel__input,
.admin-panel__file-input {
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.admin-panel__input:focus,
.admin-panel__file-input:focus {
  border-color: #007bff;
}

.admin-panel__button {
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.admin-panel__button:hover {
  background-color: #0056b3;
}

.admin-panel__button:active {
  background-color: #004085;
}

/* Product List Styling */
.admin-panel__product-list-title {
  text-align: center;
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

.admin-panel__product-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.admin-panel__product-item {
  background-color: white; /* White background for product item */
  border: 1px solid #ddd; /* Light border */
  border-radius: 6px; /* Rounded corners */
  padding: 15px; /* Padding for comfort */
  text-align: center; /* Center content */
  cursor: pointer; /* Indicate clickable item */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Animation for hover effect */
  position: relative; /* Required for positioning hot deal label */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Added shadow for depth */
}

.admin-panel__product-item:hover{
transform: scale(1.03); /* Slight scale effect on hover */
box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Increase shadow on hover for emphasis */
}
.admin-panel__product-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.admin-panel__product-name {
  font-size: 1.0rem; /* Base font size for product name */
  font-weight: 600;
  margin-top: 10px; /* Space above product name */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Updated font */
  color: #2b2b2b; /* Slightly lighter text color */
}

.admin-panel__product-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.admin-panel__edit-button,
.admin-panel__delete-button {
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.admin-panel__edit-button {
  background-color: #28a745;
  color: #fff;
}

.admin-panel__edit-button:hover {
  background-color: #218838;
}

.admin-panel__delete-button {
  background-color: #dc3545;
  color: #fff;
}

.admin-panel__delete-button:hover {
  background-color: #c82333;
}

/* Loading Spinner */
.loading-spinner {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #007bff;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-panel__form {
    gap: 10px;
  }

  .admin-panel__product-item {
    padding: 10px;
  }

  .admin-panel__product-name {
    font-size: 1rem;
  }
}

.admin-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #0c5369;
  padding: 20px;
}

.admin-login-box {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.admin-login-box h1 {
  font-size: 2rem;
  color: #e34444;
  margin-bottom: 20px;
}

.login-input {
  margin-bottom: 15px;
  width: 100%;
}

.login-input input {
  width: 80%;
  padding: 12px 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: all 0.3s ease;
}

.login-input input:focus {
  border-color: #007bff;
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.login-button button {
  width: 40%;
  padding: 12px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.login-button button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

.login-button button:active {
  background-color: #004085;
}

/* Responsive Design */
@media (max-width: 768px) {
  .admin-login-box {
    padding: 30px;
  }

  .admin-login-box h1 {
    font-size: 1.8rem;
  }

  .login-input input,
  .login-button button {
    font-size: 0.9rem;
  }
}

@media (max-width: 500px) {
  .admin-login-box {
    padding: 20px;
  }

  .admin-login-box h1 {
    font-size: 1.5rem;
  }

  .login-input input,
  .login-button button {
    font-size: 0.85rem;
  }
}

/* Hot Deal Label Styling */
.admin-panel__hot-deal-label {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff4757; /* Red color for prominence */
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: bold;
  z-index: 1;
}
