.movies-page {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .page-title {
    font-size: 2rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .category-selector {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .category-selector label {
    font-size: 1rem;
    margin-right: 10px;
    color: #555;
  }
  
  .category-dropdown {
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    width: 200px;
    cursor: pointer;
  }
  
  /* Action Buttons */
  .action-buttons {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .select-all-checkbox {
    margin-right: 8px;
  }
  
  .delete-button {
    padding: 10px 20px;
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .delete-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .delete-button:hover:not(:disabled) {
    background-color: #c0392b;
  }
  
  /* Image Grid */
  .movies-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 10px;
  }
  
  .movie-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .movie-item:hover {
    transform: scale(1.05);
  }
  
  .movie-checkbox {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #fff;
    border-radius: 50%;
    padding: 5px;
    border: 2px solid #333;
    cursor: pointer;
  }
  
  .movie-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    transition: opacity 0.3s ease;
  }
  
  .movie-item input[type="checkbox"]:checked + .movie-image {
    opacity: 0.7;
  }
  
  /* Select All and Delete Button Styling */
  .select-all-checkbox {
    margin-top: 5px;
  }
  
  .category-dropdown {
    transition: background-color 0.3s ease;
  }
  
  .category-dropdown:hover {
    background-color: #e0e0e0;
  }

  