/* Acess.css */

.forest-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .cedar-tree {
    margin-bottom: 15px;
  }
  
  .cedar-tree input, .cedar-tree select, .cedar-tree button {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .cedar-tree select {
    cursor: pointer;
  }
  
  .cedar-tree button {
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .cedar-tree button:hover {
    background-color: #2980b9;
  }
  
  .maple-tree {
    margin-top: 20px;
  }
  
  .maple-tree input {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .maple-tree button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .maple-tree button:hover {
    background-color: #27ae60;
  }
  
  .upload-progress {
    margin-top: 20px;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .upload-progress .progress-bar {
    background-color: #e0e0e0;
    border-radius: 5px;
    overflow: hidden;
    height: 20px;
  }
  
  .upload-progress .progress {
    background-color: #3498db;
    height: 100%;
    width: 0;
  }
  
  .birch-tree {
    margin-top: 30px;
  }
  
  .oak-tree {
    margin-bottom: 15px;
  }
  
  .oak-tree input {
    padding: 10px;
    font-size: 16px;
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  table th, table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  table th {
    background-color: #3498db;
    color: white;
  }
  
  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  table tr:hover {
    background-color: #ecf0f1;
  }
  
  button {
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
  }
  
  button:hover {
    opacity: 0.8;
  }
  
  button:focus {
    outline: none;
  }
  
  button:active {
    background-color: #2980b9;
  }
  
  /* Error and Success messages */
  p {
    color: #e74c3c;
    font-size: 14px;
    margin: 10px 0;
  }
  
  .toastify-container {
    font-size: 14px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .cedar-tree input,
    .cedar-tree select,
    .cedar-tree button,
    .maple-tree input,
    .maple-tree button {
      width: 100%;
      margin: 5px 0;
    }
  
    table th, table td {
      font-size: 14px;
      padding: 10px;
    }
  }
  /* General button styling */

  
  button:hover {
    transform: scale(1.05);
  }
  
  /* Save Button */
  button.save {
    background-color: #4CAF50;  /* Green */
    color: #fff;
  }
  
  button.save:hover {
    background-color: #45a049;  /* Darker Green */
  }
  
  /* Cancel Button */
  button.cancel {
    background-color: #f44336;  /* Red */
    color: #fff;
  }
  
  button.cancel:hover {
    background-color: #e53935;  /* Darker Red */
  }
  
  /* Edit Button */
  button.edit {
    background-color: #2196F3;  /* Blue */
    color: #fff;
  }
  
  button.edit:hover {
    background-color: #1e88e5;  /* Darker Blue */
  }
  
  /* Delete Button */
  button.delete {
    background-color: #f44336;  /* Red */
    color: #fff;
  }
  
  button.delete:hover {
    background-color: #e53935;  /* Darker Red */
  }
  
  /* Apply to specific buttons in JSX */
  button.save, button.cancel, button.edit, button.delete {
    margin: 0 5px;
  }
  